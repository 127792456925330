// Usamos un eventListener para seleccionar que cuando el Dom se descargue, inicie en iniciarApp()
document.addEventListener('DOMContentLoaded', function() { 
    iniciarApp();
});

function iniciarApp(){ 
    scrollNav();
    navegadorMovil();
    anioCopyright();
}

function navegadorMovil(){
    $('.nav').animate({"left" : "-=3000px"}, 10);
    $('.nav').css("display", "flex");

    $('.btn').on('click', function(){
        $('.nav').animate({"left": "+=3000px"}, 400);
        $('.btn').fadeOut(200);
        $('body').css("overflow-y", "hidden");
    });

    $('.btn-x').on('click', function() {
      $('.nav').animate({"left": "-=3000px"}, 400);
      $('.btn').fadeIn(300);
      $('body').css("overflow-y", "auto");
  })

    $('.nav > a').on('click', function() {
        $('.nav').animate({"left": "-=3000px"}, 400);
        $('.btn').fadeIn(300);
        $('body').css("overflow-y", "auto");
    })
}

function scrollNav(){
        // Add smooth scrolling to all links
        $("a").on('click', function(event) {
      
          // Make sure this.hash has a value before overriding default behavior
          if (this.hash !== "") {
            // Prevent default anchor click behavior
            event.preventDefault();
      
            // Store hash
            var hash = this.hash;
      
            // Using jQuery's animate() method to add smooth page scroll
            // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
            $('html, body').animate({
              scrollTop: $(hash).offset().top
            }, 800, function(){
      
              // Add hash (#) to URL when done scrolling (default click behavior)
              window.location.hash = hash;
            });
          } // End if
        });
}

function anioCopyright(){
  const anio = (new Date).getFullYear();
	$(document).ready(function() {
	  	$("#año").text( anio );
	});
}

function isMobile() {
  if (sessionStorage.desktop)
      return false;
  else if (localStorage.mobile)
      return true;
  var mobile = ['iphone', 'ipad', 'android', 'blackberry', 'nokia', 'opera mini', 'windows mobile', 'windows phone', 'iemobile'];
  for (var i in mobile)
      if (navigator.userAgent.toLowerCase().indexOf(mobile[i].toLowerCase()) > 0) return true;
  return false;
}

const formulario = document.querySelector('form');
const buttonSubmit = document.querySelector('submit');
const urlDesktop = 'https://web.whatsapp.com/';
const urlMobile = 'whatsapp://';
const telefono = '543412368588';

formulario.addEventListener('submit', (event) => {
  event.preventDefault()
  setTimeout(() => {
      let nombre = document.querySelector('#nombre').value
      let telContacto = document.querySelector('#telefono').value
      let direccion = document.querySelector('#direccion').value
      let checkbox = document.querySelector('#checkbox').checked
      let consulta = document.querySelector('#consulta').value
      let respuesta;
      if (checkbox == true) {
        respuesta = 'Si';
      } else {
        respuesta = 'No';
      }
      
      let mensaje = 'send?phone=' + telefono + '&text=*_Contacto desde la web_*%0A*¿Cual es tu nombre y apellido?*%0A' + nombre + '%0A*¿Cuáles es tu direccion?*%0A' + direccion + '%0A*¿Cuál es tu telefono?*%0A' +telContacto + '%0A*¿Quiere una franquicia?*%0A' +respuesta+ '%0A*Consulta:*%0A' + consulta + ''
      if(isMobile()) {
          window.open(urlMobile + mensaje, '_blank')
      }else{
          window.open(urlDesktop + mensaje, '_blank')
      }

      location.reload();
  }, 1000);
});